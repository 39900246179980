.App {
  text-align: center;
  background-image: linear-gradient(to left bottom, #ef486c, #ef4e87, #e959a2, #df66ba, #d074d0, #b989e7, #9e9cf7, #83acff, #69c2ff, #6bd4fc, #87e3f2, #abf0eb);
  background-repeat: no-repeat;
}

.navbarTop {
  background-color: rgba(255,255,255,0.6) !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.menuItem {
  /* color: white !important; */
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 600 !important;
}

.desc {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 600 !important;
}

.title {
  text-shadow: 2px 1px 0px rgba(170, 170, 170, 1);
}

.sub {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 300 !important;
}

.mockup {
  width: 70%;
  height: auto;
}

.appIcon {
  width: 30% !important;
  height: auto;
  cursor: pointer;
}

.howIcon {
  width: 40% !important;
  height: auto;
}

.aboutRow {
  /* background-color: #EDE3E4!important; */
  /* background-color: #F7F5DD!important; */
}

.colDesc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.howRow {
  /* background-color: #FFF5F6!important; */
  /* background-color: #574144!important; */
  /* background-color: #031927!important; */

}

.rowMission {
  /* background-color: #ede3e4!important; */
}

.footer {
  background-color: black!important;
}

.text-footer {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 200 !important;
}

.rotate-in-up-left {
  animation: rotate-in-up-left 2s ease 1;
}
@keyframes rotate-in-up-left {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.bounce-in-left {
  animation: bounce-in-left 2s ease 1;
}
@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.cardBg {
  background-color:  rgba(255,255,255,0.9);
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 350px;
  width: 90%;
}

.cardBgPass {
  background-color:  rgba(255,255,255,0.9);
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.gelatine {
  animation: gelatine 1.5s 1;
}
@keyframes gelatine {
  from, to { transform: scale(1, 1); }
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

.passBg {
  min-height: 100vh;
}

.pinkBtn {
  background: #ef4a74 !important;
  border-color: transparent !important;
}

.logo {
  width: 130px !important;
  height: auto;
  cursor: pointer;
}

.cgu {
  color: white !important;
  text-decoration-color: white !important;
}